<template>
  <div class="school">
    <el-carousel height="500px" indicator-position="none">
      <el-carousel-item v-for="item in 3" :key="item">
        <img src="../assets/image/school-banner.png" alt="" />
        <div class="banner-container">
          <p class="big">气象教室</p>
          <p class="sub">给孩子一个想象的未来</p>
          <div class="btn">
            <img
              src="../assets/image/icon-books.png"
              alt=""
              class="icon"
            />气象教育知识大课堂
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <ul class="category">
        <li
          class="item"
          v-for="(item, i) in linkList"
          :key="i"
          @click="goTo(item.id)"
        >
          <img :src="item.icon" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// const kxhd = ;
export default {
  name: "School",
  data() {
    return {
      linkList: [
        {
          icon: require("@/assets/icon-kxhd.png"),
          name: "科学活动",
          link: "scienceCalendar",
          id: 1
        },
        {
          icon: require("@/assets/icon-xxzy.png"),
          name: "学习资源",
          link: "resourceRecord",
          id: 2
        },
        {
          icon: require("@/assets/icon-kxlm.png"),
          name: "科学联盟",
          link: "",
          id: 3
        },
        {
          icon: require("@/assets/icon-tskc.png"),
          name: "特色课程",
          link: "course",
          id: 4
        },
        {
          icon: require("@/assets/icon-kjxy.png"),
          name: "科技校园",
          link: "",
          id: 5
        },
      ],
    };
  },
  methods: {
    goTo(id) {
      this.$router.push({ name: 'science', query: {id} });
    },
  },
};
</script>

<style lang="less">
@import url("@/styles/banner.less");
.school {
  .main {
    width: 1280px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.3);
    border-radius: 10px;
    margin: 0 auto;
    padding: 94px 60px;
    .category {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      .item {
        cursor: pointer;
        width: 350px;
        margin-right: 55px;
        &:nth-child(n + 4) {
          margin-top: 76px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    .btn {
      width: 180px;
      line-height: 60px;
      border-radius: 30px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      &.orange {
        background: linear-gradient(0deg, #ffb900, #ff9600);
        box-shadow: 0px 10px 20px 0px rgba(255, 183, 0, 0.3);
      }
      &.cyan {
        background: linear-gradient(0deg, #2fcbd9, #21a0c3);
        box-shadow: 0px 10px 20px 0px rgba(47, 203, 217, 0.3);
      }
      &.green {
        background: linear-gradient(0deg, #2ae871, #16aa6d);
        box-shadow: 0px 10px 20px 0px rgba(45, 229, 114, 0.3);
      }
      &.pink {
        background: linear-gradient(0deg, #f27ca6, #f12197);
        box-shadow: 0px 10px 20px 0px rgba(243, 124, 186, 0.3);
      }
      &.blue {
        background: linear-gradient(0deg, #9bcdfb, #076fcc);
        box-shadow: 0px 10px 20px 0px rgba(36, 140, 234, 0.3);
      }
    }
    .activity {
      margin: 28px 0;
      .title {
        text-align: center;
        color: #25282b;
        font-size: 28px;
        padding-bottom: 24px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 2px;
          background: fade(#e64569, 50);
        }
        &::after {
          content: "";
          position: absolute;
          left: calc(50% - 2em);
          bottom: 2px;
          height: 2px;
          width: 46px;
          background: fade(#e64569, 50);
          border-left: 37px solid #fff;
          border-right: 37px solid #fff;
        }
      }
    }
  }
}
</style>